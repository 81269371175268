* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #fff;
}

.app {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.img {
  width: 100%;
}
